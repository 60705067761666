// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Restaurant, Area, User, Sensor, Equipment, TemperatureReading, TemperatureRecord, TraceabilityLabel, Supplier, Product, Preparation, Delivery, DeliveryPicture, CleaningTask, CleaningTaskRecord, Cooling, Freezing, FryerCheck, FryerEquipment, SurfaceAnalysis, Reheating, Transport, Test, DateRange, Sub, Address, Settings, SensorSettings, LabelsSettings, GlobalSettings, TemperatureRecordsSettings, DeliverySettings, TraceabilitySettings, FryerChecksSettings, ContactMethod, DeliveryTemperatureRecord } = initSchema(schema);

export {
  Restaurant,
  Area,
  User,
  Sensor,
  Equipment,
  TemperatureReading,
  TemperatureRecord,
  TraceabilityLabel,
  Supplier,
  Product,
  Preparation,
  Delivery,
  DeliveryPicture,
  CleaningTask,
  CleaningTaskRecord,
  Cooling,
  Freezing,
  FryerCheck,
  FryerEquipment,
  SurfaceAnalysis,
  Reheating,
  Transport,
  Test,
  DateRange,
  Sub,
  Address,
  Settings,
  SensorSettings,
  LabelsSettings,
  GlobalSettings,
  TemperatureRecordsSettings,
  DeliverySettings,
  TraceabilitySettings,
  FryerChecksSettings,
  ContactMethod,
  DeliveryTemperatureRecord
};